@import '../../constants/variables';

.daylighting-result {
  position: absolute;
  height: 12px;
  width: 12px;
  z-index: 200;
  right: 4px;
  top: 4px;
  border-radius: 8px;

  &:hover {
    transform: scale(1.5);
  }
}
