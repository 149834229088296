.top-facade-content {
  margin: 100px 28px 28px 28px;
  select.InputElement {
    margin: 0;
    width: 160px;
  }
  .dropdown-icon-container {
    margin: 0;
    right: 115px !important;
  }
  .Label {
    width: 190px;
  }
}

.bottom-facade-content {
  margin: 56px 28px 28px 28px;
  h4 {
    text-align: left;
  }

  select.InputElement {
    margin: 0;
    width: 115px;
  }
  .dropdown-icon-container {
    margin: 0;
    right: 166px !important;
  }
  .Label {
    width: 190px;
  }
}
