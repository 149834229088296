@import '../../constants/variables';

.overview-content {
  margin-top: 100px;
  text-align: left;
  width: 100%;
  height: 94%;
  margin-top: 50px;

  .overview-cards {
    overflow-x: hidden;
    width: 100%;
    height: 80%;
    box-sizing: border-box;

    .overview-card-container {
      display: inline-block;
      text-align: center;
      margin: 0 40px 0 0;
    }
  }
}

.create-project-link {
  font-weight: 800;
  text-decoration: underline;
  color: $blue-main;
  cursor: pointer;
}
