.navbuttons {
  display: flex;
  position: absolute;
  right: 50px;
  bottom: 20px;
}

.arrow-right {
  margin-left: 12px;
}

.arrow-left {
  margin-right: 12px;
}
