@import '../../constants/variables';
.checkout-container {
  display: flex;
  flex-direction: column;

  h4 {
    margin: 22px 0;
    text-align: left;
  }

  .checkout-container-row {
    display: flex;
    flex-direction: row;

    .checkout-input-container {
      margin-left: 150px;
      display: flex;
      align-items: center;
      height: fit-content;

      .Label {
        width: 100px;
      }

      a {
        font-weight: bolder;
        text-decoration: underline;
        color: $blue-main;
        cursor: pointer;
      }

      button {
        border: none;
        background-color: $bg-color;
        font-weight: bolder;
        text-decoration: underline;
        color: $blue-main;
        font-size: 16px;
        font-family: 'Roboto';
        cursor: pointer;
      }
    }

    &:hover {
      .overview-card-image img {
        opacity: 1;
      }
      .overview-card-image {
        .overview-card-overlay {
          opacity: 0;
          visibility: hidden;
        }

        .overview-card-icons {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}
