@import '../../constants/variables';

.position-selector {
  margin: 20px 0;
  span {
    text-align: left;
  }
  .position-icons-container {
    display: flex;
    width: 80%;
    justify-content: space-between;

    .position-icon {
      margin-top: 20px;
      height: auto;
      width: 90px;
      color: $blue-main;
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    .position-icon-active {
      margin-top: 20px;
      height: auto;
      width: 90px;
      color: $blue-main;
      cursor: pointer;
      opacity: 1;
    }
  }
}
