@import '../../constants/variables';

.bp-container {
  display: flex;
  height: 50px;
  justify-content: flex-start;
  align-items: center;
  color: $blue-dark;
  transition-property: box-shadow, color;
  transition-duration: 0.5s;
}
.bullet {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 30px 0 10px;
  min-width: 24px;
  min-height: 24px;
  height: 24px;
  width: 24px;
  background-image: $gradient-blue;
  border-radius: 15px;
  color: white;
  font-size: 10px;
  transition-property: box-shadow;
  transition-duration: 0.5s;
  z-index: 1;

  &.active {
    margin-left: 6px;
    border: 4px solid $bg-color;
    border-radius: 24px;
    box-shadow: 0 0 5px $blue-dark;
    height: 24px;
    width: 24px;
  }

  &.active ~ .bullet-caption {
    margin-left: -4px;
  }

  &.inverted {
    background-image: linear-gradient(to right, $blue-disabled, $blue-disabled);
  }
}

.bullet-caption {
  width: 100px;
  overflow-wrap: normal;
  text-align: left;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
}

.inactive {
  color: $blue-disabled;
}

.bullet-connection-line {
  position: absolute;
  height: 14vh;
  width: 1px;
  left: 22px;
  background-color: $blue-dark;
  transform: translateY(-8vh);
  transition-property: background-color;
  transition-duration: 0.5s;

  &.inactive {
    background-color: $blue-disabled;
  }
}
