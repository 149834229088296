@import '../../constants/variables';

.preview-component {
  position: relative;
  color: $blue-main;
  font-size: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .axis {
    margin: 6px 16px;
    display: flex;
    align-items: center;
    transform: translateX(-35px);
    &-span {
      display: flex;
      align-items: center;
      color: $blue-main;
      font-weight: bolder;
      margin: 0 4px;
      .floors-icon {
        margin: 0px 10px;
      }
    }
  }

  .preview-header {
    display: flex;
    height: 28px;
    box-sizing: border-box;

    .nav {
      flex: 1;
      justify-content: flex-end;

      display: flex;
      align-items: center;

      .nav-item {
        float: right;
        margin: 6px 16px;
      }

      .nav-item-bold {
        margin: 6px 16px;
        font-weight: bolder;
      }

      .nav-item-reset {
        margin: 6px auto;
        font-weight: bolder;
        cursor: pointer;
        font-size: 16px;
        animation: fadeIn 0.4s 0.3s backwards;
      }
    }
  }

  .preview-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 28px 28px 0px 28px;
    margin-left: 28px;
    margin-right: 14px;
    width: calc(100%-10px);
    overflow: hidden;

    .vertical-line {
      position: absolute;
      left: 0;
      top: 24px;
      margin: 8px 0px 8px 9px;
      background-color: $blue-disabled;
      width: 1px;
      height: 58vh;
    }
  }

  .preview-footer {
    display: flex;
    height: 28px;
    justify-self: flex-end;

    .horizontal-line {
      margin: 12px -8px 8px 24px;
      flex: 1;
      background-color: $blue-disabled;
      height: 1px;
      .axis {
        color: red;
      }
    }

    .axis {
      transform: translateX(0px);
    }
  }

  .preview-point {
    position: absolute;
    height: 5px;
    width: 5px;
    background-color: $blue-disabled;
    border-radius: 5px;
    bottom: 13px;
    left: 7px;
  }

  .preview {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 90%;
    overflow: hidden;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
