@import '../../constants/variables';

.new-facade-modal-section {
  opacity: 1;
  transform: scaleY(1);
  max-height: 200px;
  width: 100%;
  transition: all 0.3s 0.2s ease-in-out;
  transform-origin: top;
  overflow: auto;
  margin: 16px 0;
}

.new-facade-modal-section-collapsed {
  opacity: 0;
  transform: scaleY(0);
  height: 0px;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  transform-origin: top;
}

.selection-label {
  color: $blue-main;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  padding-left: 35%;
  padding-bottom: 12px;
  align-items: center;
  border-bottom: 1px solid $blue-disabled;

  &:last-child {
    border: none;
    margin-top: 24px;
  }
}
