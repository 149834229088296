.daylight-result-values {
  position: fixed;
  background-color: white;
  width: 500px;
  font-size: 12px;
  text-align: left;
  top: 50px;
  left: 70%;
  transform: translate(-50%, 0);
  z-index: 3000;
  box-shadow: 5px 5px 20px rgba(gray, 0.3);
  opacity: 0.8;
  padding: 20px;
}

.daylight-results-value-item {
  margin-bottom: 10px;
}
