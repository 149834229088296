@import '../../constants/variables';

.curb {
  height: 36px;
  position: relative;
  margin: -1px 0 0 -1px; //to easily avoid 2 border lines of 2 adjacent elements
  .cassette {
    height: 100%;
    float: left;
  }
}
