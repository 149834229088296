@import '../../constants/_variables';

.select-panel {
  margin-bottom: 60px;

  .panel-select-fields {
    cursor: pointer;
    width: fit-content;
    margin: 30px 0;
    color: $blue-main;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .select-disabled {
    pointer-events: none;
    width: fit-content;
    margin: 30px 0;
    color: $blue-disabled;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
