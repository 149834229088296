.sidenav-container {
  position: absolute;
  display: flex;
  height: 75vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: center;
  margin: 30px;
  margin-top: 100px;
}
