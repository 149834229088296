@import '../../constants/variables';

.facade-navbar-container {
  position: fixed;
  padding: 10px 50px 50px 28px;
  top: 170px;
  background-color: $bg-color;
  z-index: 10;

  h4 {
    text-align: left;
    display: block;
    width: 79%;
    position: absolute;

    .style-label {
      float: right;
    }
  }
}

nav {
  width: 375px;
  height: 40px;

  button {
    border: none;
    outline: none;
    font-size: 15px;
    font-family: 'Roboto';
  }
}

nav button.active {
  color: #fff;
}

nav button {
  color: $blue-disabled;
  text-decoration: none;
  line-height: 40px;
  position: relative;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  width: 125px;
  background-color: transparent;
  z-index: 50;
  transition: color 0.5s ease 0s;
}
nav .animation {
  position: absolute;
  height: 40px;
  top: 10px;
  background: $gradient-blue;
  box-shadow: 5px 5px 10px rgba(black, 0.3);
  transition: all 0.5s ease 0s;
  z-index: 0;
}

nav .start-front,
nav .start-rear,
nav .start-gable {
  color: #fff;
}

nav .start-front,
button:nth-child(1) .animation {
  width: 125px;
  left: 28px;
}

nav .start-rear,
button:nth-child(2) .animation {
  width: 125px;
  left: 153px;
}

nav .start-gable,
button:nth-child(3) .animation {
  width: 125px;
  left: 278px;
}
