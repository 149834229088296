@import '../../constants/variables';

.existing-modal {
  width: 100%;

  .panel-selector-label {
    font-size: 18px;
    text-align: left;
    margin-bottom: 40px;
  }

  &-inputs {
    padding-left: 20px;
  }
}
.facade-modal-section {
  max-height: 55%;
  width: 100%;
  overflow: auto;
}

.modal-container {
  opacity: 0;
  max-height: 50%;
  width: 90%;
  margin: 24px;
  display: flex;
  flex-wrap: wrap;
}

.modal-container.active {
  opacity: 1;
  height: 360px;
  width: 90%;
  margin: 24px;
  display: flex;
  flex-wrap: wrap;
  transition: opacity 0.3s;
  justify-content: flex-start;
  padding-bottom: 20px;

  .cassette-container {
    height: 38%;
    margin: 12px auto;
    padding: 0px 6px;
    flex-basis: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .cassette {
      height: 100%;
      position: relative;
      width: fit-content;
    }

    .checkmark {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s;
      position: absolute;
    }

    .checkmark-active {
      width: 18px;
      height: 18px;
      background-color: #55d595;
      color: #fff;
      line-height: 18px;
      font-size: 11px;
      border-radius: 18px;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translate(25%, 25%);
      transition: opacity 0.3s;
    }
  }

  &::-webkit-scrollbar-track {
    background: $blue-disabled;
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
  }
}
