.homepage-icons-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 314px;
  justify-content: space-between;
  align-items: center;
  left: -220px;
  top: 25%;
  z-index: 10;

  .active {
    cursor: pointer;
  }
}
