@import '../../constants/variables';

.top-facade-content {
  .design-option {
    box-shadow: 5px 5px 20px rgba(gray, 0.3);
    margin-bottom: 32px;
    width: 85%;
    height: 200px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .design-option-disabled {
    box-shadow: 5px 5px 20px rgba(gray, 0.3);
    margin-bottom: 32px;
    width: 85%;
    height: 200px;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
      opacity: 0.5;
    }
  }

  .design-option-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 110%;
    width: 100%;
    opacity: 0.5;
    transition: 0.5s ease;
    background-color: $blue-mid;
  }

  .design-option:nth-last-child(1) {
    margin-bottom: 80px;
  }

  .checkmark {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    position: absolute;
  }

  .checkmark-active {
    width: 18px;
    height: 18px;
    background-color: #55d595;
    color: #fff;
    line-height: 18px;
    font-size: 11px;
    border-radius: 18px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-75%, 50%);
    transition: opacity 0.3s;
  }
}
