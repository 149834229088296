@import '../../constants/variables';

.unit {
  display: flex;
}

.unit-interactive {
  display: flex;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.unit-interactive-absolute {
  position: absolute;
  display: flex;
  transform: scale(2) translateX(-22%);
  transition: transform 0.5s;
  z-index: 2000;
}

.unit-interactive-hidden {
  position: absolute;
  display: flex;
  opacity: 1;
}
