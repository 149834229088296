@import '../../constants/variables';

.panel {
  height: 90px;
  min-width: 45px;
  position: relative;
  margin: -1px 0 0 -1px; //to easily avoid 2 border lines of 2 adjacent elements

  &-text {
    position: absolute;
    left: 0;
    top: 0;
  }

  .cassette {
    height: 100%;
    float: left;
  }
}

.cassette-overlay {
  opacity: 0;
  visibility: hidden;
}
.cassette-overlay-icons {
  opacity: 0;
  visibility: hidden;
  transform: scale(0.85);
}

.panel-interactive {
  height: 60px;
  min-width: 45px;
  position: relative;
  margin: -1px 0 0 -1px; //to easily avoid 2 border lines of 2 adjacent elements

  &:hover {
    transform: scale(1.05);
    z-index: 2000;
    border: 1px solid $blue-main;
    box-sizing: border-box;
    .cassette-overlay {
      position: absolute;
      opacity: 1;
      visibility: visible;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      background-color: $blue-disabled;
      opacity: 0.6;
      display: flex;
      justify-content: center;
      align-items: center;

      .cassette-overlay-icons {
        opacity: 1;
        visibility: visible;
        cursor: pointer;
      }
    }
  }

  .cassette {
    height: 100%;
    float: left;
  }
}

.cassette-mirrored {
  height: 100%;
  float: left;
  transform: scaleX(-1);
  .cassette-overlay {
    transform: scaleX(-1);
  }
}
