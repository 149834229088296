@import '../../constants/variables';

.overview-card {
  position: relative;
  margin: 20px 0 40px 0;
  background-color: #ffffff;
  width: 350px;
  color: $blue-main;
  box-shadow: 5px 5px 10px rgba(black, 0.1);
  height: fit-content;

  &-item {
    position: relative;
    font-weight: 15px;
    display: flex;
    justify-content: space-between;
    padding: 18px 120px 18px 60px;
    border-bottom: 1px solid $blue-disabled;

    span {
      text-align: right;
      margin-left: auto;
      font-weight: bolder;
      font-weight: 18px;
    }

    .currency {
      bottom: 50%;
      margin-left: 5px;
      transform: translateY(50%);
      color: $blue-disabled;
      position: absolute;
      font-size: 12px;
    }

    .hover {
      cursor: pointer;
    }
  }

  .overview-card-icons {
    opacity: 0;

    img {
      width: auto;
      height: 20%;
    }
  }

  &-image {
    position: relative;
    height: 220px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:hover {
    .overview-card-image img {
      opacity: 0.5;
      transition: opacity 0.5s ease;
    }

    .overview-card-icons {
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 1;
      visibility: visible;
      transition: 0.5s ease;
      top: 0;
      left: 0;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      img {
        width: auto;
        height: 20%;
        cursor: pointer;
      }
    }
  }

  .hover-card {
    position: absolute;
    width: 420px;
    text-align: left;
    left: 500px;
    top: 60px;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 5px 5px 20px rgba(gray, 0.3);
    padding: 30px;
    .newline {
      margin-bottom: 20px;
    }
  }
}
