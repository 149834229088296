.content-container.column {
  .row {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: row;

    .right {
      display: flex;
      height: 85%;
      max-height: 85%;
      width: 100%;
      overflow: hidden;
      margin-right: 30px;
    }
  }
}
