@import '../../constants/variables';

.modal {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(black, 0.4);
  z-index: 200;
  opacity: 1;
  transition: all 0.3s;

  @supports (-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px)) {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(#9b9b9b, 0.23);
  }

  &.inactive {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s 0.2s;
  }

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 28%;
    min-width: 490px;
    min-height: 80%;
    background-color: #fff;
    box-shadow: 0 15px 30px rgba(black, 0.2);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    transition: all 0.5s 0.2s;
    padding: 10px 24px;

    &.inactive {
      position: relative;
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
      transition: all 0.3s;
    }
  }

  &-buttons {
    display: flex;
    width: 338px;
    justify-content: space-between;
    justify-self: flex-end;
    margin-bottom: 24px;
  }
}

.modal-children {
  display: flex;
  flex: 1;
  width: 100%;
  height: 80%;
}
