@import '../../constants/variables';

.custom-button {
  min-width: 154px;
  width: auto;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  background: $gradient-blue;
  color: white;
  font-family: 'Open Sans';
  font-weight: 600;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 10px rgba(black, 0.3);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 3px 6px rgba(black, 0.3);
  }

  &.inverted {
    background: transparent;
    color: $blue-main;
    border: 1px solid $blue-main;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 5px 8px rgba(black, 0.3);
    }

    &:active,
    &:focus {
      outline: none;
      transform: translateY(-1px);
      box-shadow: 0 3px 6px rgba(black, 0.3);
    }
  }
}
button:disabled {
  pointer-events: none;
  opacity: 0.5;
}
